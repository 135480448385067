/*
 
   _____         _  _____     _              ____        _        
  |_   _|__  ___| ||_   _|__ | | _____ _ __ |  _ \  __ _| |_ __ _ 
    | |/ _ \/ __| __|| |/ _ \| |/ / _ \ '_ \| | | |/ _` | __/ _` |
    | |  __/\__ \ |_ | | (_) |   <  __/ | | | |_| | (_| | || (_| |
    |_|\___||___/\__||_|\___/|_|\_\___|_| |_|____/ \__,_|\__\__,_|
                                                                  
 
*/

@import url(colors.css);


/*=============================================
=            Background            =
=============================================*/

#test-token-data-background
{
    display: none;
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    width: 100%;
    height: 100vh;
}



/*=============================================
=            main-container            =
=============================================*/

#ttd-main-container{
    background-color: white;
    overflow-y: auto;
    width: 60%;
    height: 100vh;
    margin: 0 auto;
    padding: .5em 0;
    border-right: 1px solid var(--background-color);
    border-left: 1px solid var(--background-color);
}

#ttd-main-container h4{
    font-family: Blinker;
    font-size: 1.4rem;
    color: var(--cyan-clicked);
    text-align: center;
    margin: 0;
}


/*=============================================
=            tokens container            =
=============================================*/

#tokens-container{
    font-family: 'Roboto Condensed';
    background: var(--darker-white);
    width: 80%;
    height: 80vh;
    margin: 4% auto 0;
    border: calc(1px - .02em) solid var(--light-grey);
    border-radius: 5px;
}


/*----------  tokens  ----------*/
.user-ttoken{
    cursor: pointer;
    color: white;
    padding: 1em;
    border-bottom: 1px solid var(--light-grey);
    transition: all .3s ease-in;
}

.user-ttoken .token-upper-info-container, .user-ttoken .token-info{
    display: flex;
    width: 100%;
    /* justify-content: space-between; */
}

.token-upper-info-container .interviewee-name{
    width: 75%;
}

.token-upper-info-container .interviewee-name input{
    font-family: 'Roboto Condensed';
    background: none;
    color: white;
    font-size: 1.2rem;
    border: none;
}

.token-upper-info-container .token-status{
    width: 20%;
    color: rgba(255, 255, 255, 0.7);
}

.token-upper-info-container .del-btn{
    transition: color 1s ease-in-out;
}

.token-upper-info-container .del-btn:hover{
    color: rgba(247, 77, 77, 0.8);
}

.user-ttoken .token-info{
    justify-content: space-around;
    font-size: 0.9rem;
    margin-top: 1%;
}

.user-ttoken.token-test-done{
    background-color: var(--aprove-green);
}

.user-ttoken.token-test-done:hover{
    background-color: rgba(111, 238, 111, 0.8);
}

.user-ttoken.token-test-pending{
    background-color: var(--danger);
}

.user-ttoken.token-test-pending:hover{
    background: rgba(226, 69, 69, 0.8);
}


/*=============================================
=            close btn            =
=============================================*/

#ttd-close-btn{
    color: var(--dark-grey);
    text-align: center;
    margin-top: 2%;
}

#ttd-close-btn i{
    cursor: pointer;
    font-size: 3em;
}


