/*
 
   _       _                  _                        
  (_)_ __ | |_ ___ _ ____   _(_) _____      _____ _ __ 
  | | '_ \| __/ _ \ '__\ \ / / |/ _ \ \ /\ / / _ \ '__|
  | | | | | ||  __/ |   \ V /| |  __/\ V  V /  __/ |   
  |_|_| |_|\__\___|_|    \_/ |_|\___| \_/\_/ \___|_|   
                                                       
 
*/
@import url('colors.css'); 


/*=============================================
=            A1            =
=============================================*/


/*----------  General  ----------*/

#a1-test-container{
    font-family: 'Roboto Condensed';
    width: 100%;
    padding: 1%;
}


/*----------  title  ----------*/

#title-questionary-container{
    font-family: 'Blinker';
    text-align: center;
    width: 60%;
    font-size: 1.5rem;
    color: var(--grey);
    border-bottom: 8px solid var(--cyan);
    margin: 0 auto;
}


/*----------  Question  ----------*/

#a1-question-container{
    width: 80%;
    background-color: var(--light-grey);
    margin: 2% auto;
    padding: 1%;
    border: 1px solid var(--grey); 
    border-radius: 20px
}

#a1-question-container h3, #a1-question-container h3 span{
    color: var(--dark-grey);
    text-align: center
}

#a1-question-container span{
    margin-left: 3%;
    color: var(--grey);
}


/*----------  Anwsers  ----------*/

#awnsers-container{
    display: flex;
    width: 60%;
    flex-direction: column;
    margin: 0 auto;
}

#awnsers-container .awnser-text-container{
    cursor: pointer;
    border-bottom: 2px solid var(--ligth-cyan);
    padding-left: 7%;
    transition: all 0.2s ease-in-out 
}

#awnsers-container .awnser-text-container:hover{
    background-color: var(--ligth-cyan);
}

#awnsers-container .awnser-text-container h4::before{
    content: '> ';
    font-size: 1.4rem;
    color: var(--cyan-clicked)
}


/*----------  Range answer  ----------*/

#range-answer-container{
    padding-bottom: 3%; 
    border-top: 4px solid var(--ligth-cyan);
    border-bottom: 4px solid var(--ligth-cyan);
}

#range-answer-text-container{
    display: flex;
    width: 57%;
    justify-content: space-between;
    margin: 0 auto;
}

#answer-inputs-container{
    width: 57%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto; 
}

.range-anwser-input{
    cursor: pointer;
    border:2px solid var(--cyan);
    transform: scale(1.2);
}


/*----------  Boton Next  ----------*/

#next-question-btn-container{
    display: flex;
    justify-content:flex-end;
    padding: 5% 10%; 
}

#next-question-btn-container .boton-controls{
    font-family: 'Roboto Condensed';
    background-color: var(--cyan);
    color: white;
    font-size: 1.1rem;
    padding: 1% 3%;
    margin-left: 2%;
    border: none;
    border-radius: 5px;
    transition: all 0.2s ease-in; 
}

#next-question-btn-container .boton-controls:hover{
    cursor: pointer;
    background-color: var(--sub-section)
}