/*
 
   ____       _   _   _                 _   _             __  __           _       _ 
  / ___|  ___| |_| |_(_)_ __   __ _ ___| \ | | __ ___   _|  \/  | ___   __| | __ _| |
  \___ \ / _ \ __| __| | '_ \ / _` / __|  \| |/ _` \ \ / / |\/| |/ _ \ / _` |/ _` | |
   ___) |  __/ |_| |_| | | | | (_| \__ \ |\  | (_| |\ V /| |  | | (_) | (_| | (_| | |
  |____/ \___|\__|\__|_|_| |_|\__, |___/_| \_|\__,_| \_/ |_|  |_|\___/ \__,_|\__,_|_|
                              |___/                                                  
 
*/

@import url('colors.css');


/*=============================================
=            Background            =
=============================================*/

#settings-nav-background{
    display: none;
    cursor: pointer;
    background: rgba(90, 90, 90, .2);
    position: fixed;
    height: 100%;
    width: 100%;
    
}


/*=============================================
=            Token giver            =
=============================================*/

#token-giver{
    cursor: default;
    width: 35%;
    max-width: 35%;
    background: white;
    align-self: center;
    margin: 0 0 0 30%;
    text-align: center;
    padding: 1.5%;
    border-radius: 5px;
}

@keyframes token-clicked {
    0%{transform: scale(1);}
    25%{transform: scale(1.1);}
    50%{transform: scale(1);}
    75%{transform: scale(1.1);}
    100%{transform: scale(1);}
}

#token-container{
    font-family: 'Roboto Condensed';
    width: 80%;
    cursor: pointer;
    padding: .5vh;
    color: rgba(0, 0, 0, .5);
    border: calc(1px + .05em) solid rgba(0, 0, 0, .1);
    border-radius: 2px;
    text-align: center;
    user-select: none;
}

#token-container:hover{
    color: var(--sub-section);
}

#token-container:active{
    animation-name: token-clicked;
    animation-duration: .2s;
    animation-iteration-count: infinite;
}



/*=============================================
=            modal container            =
=============================================*/

#settings-nav-modal{
    cursor: default;
    background: var(--background-color);
    width: 20%;
    height: 100%;
    color: white;
    margin-left: auto;
}



/*----------  title  ----------*/

#settings-nav-title{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 5% 0;
    border-bottom: 1px solid var(--cyan-clicked);
}

#settings-nav-title h4{
    font-family: Blinker;
    font-size: 1.6rem;
    margin: 0;
}


/*----------  Setting options  ----------*/

.settings-nav-option{
    cursor: pointer;
    font-family: 'Roboto Condensed';
    display: flex;
    font-size: 1.2rem;
    justify-content: center;
    padding: 4%;
    transition: all .1s ease-out;
}

.settings-nav-option:hover{
    background: rgba(255, 255, 255, .1);
}

.settings-nav-option:active{
    background: var(--cyan-clicked);
}

#settings-nav-options h5{
    margin: 0;
}
