/*
 
   __  __       _       __  __                  
  |  \/  | __ _(_)_ __ |  \/  | ___ _ __  _   _ 
  | |\/| |/ _` | | '_ \| |\/| |/ _ \ '_ \| | | |
  | |  | | (_| | | | | | |  | |  __/ | | | |_| |
  |_|  |_|\__,_|_|_| |_|_|  |_|\___|_| |_|\__,_|
                                                
 
*/

img[alt="www.000webhost.com"]{
	display: none;
}


@import url('colors.css');

#options-page{
    height:100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
#back-arrow{
    cursor: pointer;
    font-size: 3rem;
    margin: 1%;
    color: var(--grey);
    transition: all 0.3s ease-in-out;
}

#back-arrow:hover{
    color: var(--cyan)
}