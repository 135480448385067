/*
 
   _____     _              _   _               _____           _ 
  |_   _|__ | | _____ _ __ | | | |___  ___ _ __| ____|_ __   __| |
    | |/ _ \| |/ / _ \ '_ \| | | / __|/ _ \ '__|  _| | '_ \ / _` |
    | | (_) |   <  __/ | | | |_| \__ \  __/ |  | |___| | | | (_| |
    |_|\___/|_|\_\___|_| |_|\___/|___/\___|_|  |_____|_| |_|\__,_|
                                                                  
 
*/

@import url('colors.css');

/*=============================================
=            main-container            =
=============================================*/

#token-user-done{
    font-family: 'Roboto Condensed';
    overflow: -moz-hidden-unscrollable;
    width: 100vw;
    height: 95vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--grey);
    border-top: 7px solid var(--cyan);
}


/*=============================================
=            content            =
=============================================*/

#tud-content{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}



/*----------  smile  ----------*/

#nice-face-container{
    color: var(--aprove-green);
    font-size: 10rem;
    text-align: center;
}


/*----------  main-message  ----------*/

#main-message-container{
    font-size: 2rem;
}

#sub-msg-container, #main-message-container{
    text-align: center;
}




