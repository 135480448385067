@import url('https://fonts.googleapis.com/icon?family=Material+Icons');


:root{
    --darker-white: rgba(241, 241, 241, 0.2);
    --light-grey: rgb(241, 241, 241);
    --grey: rgb(130,130,130);
    --dark-grey: rgb(44, 44, 44);
    --cyan: rgb(0, 247, 255);
    --ligth-cyan: rgb(180, 252, 255);
    --cyan-clicked: rgb(12, 176, 182);
    --sub-section: rgb(3, 227, 235);
    --background-color: rgb(10, 86, 131);
    --danger: rgb(226, 69, 69);
    --clicked-danger: rgb(161, 51, 51);
    --aprove-green: rgb(111, 238, 111);
}

input {
  outline: none;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}


/*----------  scroll toggles  ----------*/

::-webkit-scrollbar{
    width: 8px;
  }
  
  ::-webkit-scrollbar-thumb{
    background: var(--cyan-clicked);
    border-radius: 4px;
  }
  
  ::-webkit-scrollbar-track{
    background-color: var(--light-grey);
  }



/*=============================================
=            Generics            =
=============================================*/

.pt-btn {
  font-family: "Blinker";
  cursor: pointer;
  background: var(--cyan);
  color: white;
  border: none;
  padding: 1% 2.5%;
  border-radius: 5px;
  user-select: none;
}

.pt-btn.disabled-btn {
  pointer-events: none;
  background: var(--ligth-cyan);
}


