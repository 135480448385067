@import url('colors.css');

/*
 
                                   _ 
    __ _  ___ _ __   ___ _ __ __ _| |
   / _` |/ _ \ '_ \ / _ \ '__/ _` | |
  | (_| |  __/ | | |  __/ | | (_| | |
   \__, |\___|_| |_|\___|_|  \__,_|_|
   |___/                             
 
*/



#options-page.non-vertical{
    height:90%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}


/*=============================================
=            Controls            =
=============================================*/

#tests-menu-controls{
    display: flex;
    justify-content: space-between;
}

#tests-menu-controls span{
    cursor: pointer;
    font-size: 3rem;
    margin: 1%;
    color: var(--grey);
    transition: all 0.3s ease-in-out;
}

#back-arrow:hover{
    color: var(--cyan)
}

#settings-btn{
    transition: all .07s linear;

}

#settings-btn:active{
    color: var(--cyan); 
}


/*=============================================
=            Form container            =
=============================================*/

#form-title-container{
    display: flex;
    font-family: 'Blinker';
    font-size:1.6rem;
    text-align: center;
    justify-content: center;
    /* margin-bottom: 20% */
}

#form-options-container{
    font-family: 'Roboto Condensed';
    width: 80%;
}



/*=============================================
=            Fieldset inputs            =
=============================================*/


fieldset#inputs-container{
    width: 40%;
    margin: 0 auto;
    border: none
}

.form-input-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-input-container label{
    align-self: flex-start;
    margin: 0 0 1% 10%;
    font-size: 1.5rem;
    color: var(--sub-section);
}

.form-input-container input,.form-input-container select{
    display: block;
    width: 70%;
    border-radius: 5px;
    border: 3px solid var(--grey);
    font-size: 1.3rem;
    margin: 0 0 3% 3%;

}


/*----------  ok btn  ----------*/

/* #ok-btn-container{
    width: 90%
} */

#test-menu-ok-btn{
    cursor: pointer;
    font-family: 'Roboto Condensed';
    display: block;
    width: 15%;
    background-color: var(--sub-section);
    color: white;
    margin: 2% auto;
    padding: 1%;
    border-radius: 5px;
    border: none;
}



/*----------  General Boton  ----------*/

.boton{
    cursor: pointer;
    font-family: 'Roboto Condensed';
    font-size: 1.2rem;
    display: block;
    width: 15%;
    background-color: var(--sub-section);
    color: white;
    padding: 1%;
    border-radius: 5px;
    border: none
}



/*=============================================
=            Unfinished Tests            =
=============================================*/

#unfinished-tests-modal-btn-container{
    width: 100%;
    display: flex;
    justify-content: center;

}

#unfinished-tests-modal-container{
    cursor: pointer;
    width: 40%;
    height: 3rem;
    box-sizing: border-box;
    background: var(--danger);
    color: white;
    margin: 3% 0;
    padding: 1%;
    border-radius: 5px;
    text-align: center;
    transition: all .3s ease-in;
}

#unfinished-tests-modal-container h5{
    font-family: 'Blinker';
    margin: 0;

}

#unfinished-tests-modal-container:hover{
    transform: scale(1.02);
}

#unfinished-tests-modal-container:active{
    background: var(--clicked-danger);
    transform: scale(0.98);
}
