/*
 
    ____                _       _   _               ____             __ _ _      
   / ___|_ __ ___  __ _| |_ ___| \ | | _____      _|  _ \ _ __ ___  / _(_) | ___ 
  | |   | '__/ _ \/ _` | __/ _ \  \| |/ _ \ \ /\ / / |_) | '__/ _ \| |_| | |/ _ \
  | |___| | |  __/ (_| | ||  __/ |\  |  __/\ V  V /|  __/| | | (_) |  _| | |  __/
   \____|_|  \___|\__,_|\__\___|_| \_|\___| \_/\_/ |_|   |_|  \___/|_| |_|_|\___|
                                                                                 
 
*/


/*=============================================
=            Background            =
=============================================*/


#newprofile-modal-background{
    cursor: pointer;
    display: none;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 200;
}



/*=============================================
=            form container            =
=============================================*/


#newprofile-form-container{
    cursor: default;
    background: white;
    overflow-y: auto;
    width: 80%;
    height: 100%;
    margin: 0 auto;
    border: 1px solid var(--grey);  
    box-shadow: 0 0 28px 10px rgba(0, 0, 0, .2);
}


/*=============================================
=            profile name            =
=============================================*/

#profile-name-container{
    font-family: 'Roboto Condensed';
    display: flex;
    width: 40%;
    font-size: 1.3rem;
    justify-content: space-between;
    color: var(--background-color);
    margin: 2% auto;    
}

#profile-name-container input{
    font-family: 'Roboto Condensed';
    width: 68%;
    border: none;
    border-bottom: 2px solid var(--grey);
    padding: 1%;
}


/*=============================================
=            alerts container            =
=============================================*/

#alertsbox{
    display: flex;
    justify-content: center;
}




/*=============================================
=            tests list            =
=============================================*/


#profile-tests-container{
    width: 80%;
    margin: 5% auto;
}

.messege-descriptor{
    font-family: 'Roboto Condensed';
    color: var(--grey);
    margin-left: 8%;
}

#tests-list{
    width: 75%;
	max-height: 25vh;
	text-align: center;
	margin: 3% auto;
	overflow:auto;
	/* border-bottom: 2px solid var(--grey); */
}

#tests-list h5{
    margin: 0;
}

.test-option{
	cursor: pointer;
    font-family: 'Roboto Condensed';
    color:rgb(90,90,90);
	padding: 2% 0;
	border-bottom: 1px solid var(--cyan);
}


.test-option:hover{
	background-color: var(--ligth-cyan);
}



/*=============================================
=            Stats            =
=============================================*/

#profile-stats-conainer{
    font-family: 'Roboto Condensed';
    overflow-y: auto;
    width: 80%;
    height: 30vh;
    color: var(--grey);
    margin: 5% auto;
    padding: 3%;
    border-top: 1px solid var(--dark-grey);
    border-bottom: 1px solid var(--dark-grey);
}

.profile-testattribs-container{
    width: 100%;
    background: var(--cyan);
    padding-bottom: 1rem;
}

.profile-testattribs-container:first-child{
    border-radius: calc(.30rem - 1px) calc(.30rem - 1px) 0 0;
}

.profile-testattribs-container:last-child{
    border-radius:  0 0 calc(.30rem - 1px) calc(.30rem - 1px);
}

h2.profile-testattribs-code{
    font-family: 'Blinker';
    background-color: rgba(0, 0, 0, .03);
    margin: 0;
    color: white;
    padding: calc(.75rem - 1px) 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    border-top: 1px solid rgba(0, 0, 0, .125);
}

.profile-testattribs-container:first-child h2{
    border-top: none;
}


/*----------  Attribut  ----------*/

.profile-test-attrib{
    display: flex;
    width: 50%;
    color: white;
    justify-content: space-around;
    margin: 1rem 0 0 1.3rem;
}

.profile-test-attrib-name{
    font-size: 1.1rem;
    margin: 0;
    text-transform: capitalize;
}

.profile-attrib-input{
    width: 20%;
}

.profile-attrib-input label{
    font-size: .8rem;
    margin-right: .6rem;
}

.profile-attrib-input input{
    font-family: 'Roboto Condensed';
    background: rgba(0, 0, 0, 0);
    width: 40%;
    color: white;
    border: none;
    border-bottom: .15em solid rgba(0, 0, 0, .225);
}


/*=============================================
=            controls            =
=============================================*/


#profile-controls{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 2%;
}


#create-profile-btn{
    font-family: 'Roboto Condensed';
    cursor: pointer;
    padding: 0.1rem 4%;
    border: 1px solid var(--cyan);
    border-radius: 5px;
    transition: all .6s ease-in/*cubic-bezier(0.4, -0.29, 0.53, 0.79);*/
}

.ready-btn-state{
    background: var(--cyan);
    color: white;   
}

.ready-btn-state:active{
    background: var(--cyan-clicked);
    border-color: var(--cyan-clicked) !important;
}

.not-ready-btn-state{
    background: white;
    color: var(--cyan);
}







