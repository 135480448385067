/*
 
                __ _       _     _              _ _____         _       
   _   _ _ __  / _(_)_ __ (_)___| |__   ___  __| |_   _|__  ___| |_ ___ 
  | | | | '_ \| |_| | '_ \| / __| '_ \ / _ \/ _` | | |/ _ \/ __| __/ __|
  | |_| | | | |  _| | | | | \__ \ | | |  __/ (_| | | |  __/\__ \ |_\__ \
   \__,_|_| |_|_| |_|_| |_|_|___/_| |_|\___|\__,_| |_|\___||___/\__|___/
                                                                        
 
*/
@import url('colors.css');


/*=============================================
=            Main container            =
=============================================*/

#select-ut-modalbackground{
    display: none;
    width: 100%;
    position: fixed;
    z-index: 3;

}

#select-ut-container{
    display: flex;
    background: white;
    width: 35%;
    flex-direction: column;
    align-items: center;
    margin: 8% auto;
    border: 1px solid var(--grey);
    border-radius: 5px;
    box-shadow: 0 0 14px 0 rgb(197, 197, 197);
}


/*=============================================
=            titulo            =
=============================================*/

#select-ut-title-container{
    font-family: 'Blinker';
    color: var(--grey)
}


/*=============================================
=            uts Options            =
=============================================*/

#uts-container{
    font-family: 'Roboto Condensed';
    overflow-y: auto;
    width: 70%;
    height: 20rem;
    max-height: 20rem;
    margin-bottom: 6%;
    border-right: 1px solid var(--grey);
    border-left: 1px solid var(--grey);
}

.ut-option-container{
    cursor: pointer;
    background: var(--danger);
    color: white;
    padding: 4%;
    text-align: center;
    box-shadow: inset 0 0 5px 0 var(--grey);
    transition: all .2s ease-in-out;
}

.ut-option-container:first-child{
    margin-top: 2%;
}

.ut-option-container:active{
    background: var(--clicked-danger);
}


/*=============================================
=            controls            =
=============================================*/


#select-uts-controls{
    display: flex;
    cursor: pointer;
    color: var(--dark-grey);
    font-size: 1.3rem;
    margin-bottom: 4%;
    transform: rotate(180deg);
}

#select-uts-controls:active{
    color: var(--grey);
}




