@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
:root{
    --darker-white: rgba(241, 241, 241, 0.2);
    --light-grey: rgb(241, 241, 241);
    --grey: rgb(130,130,130);
    --dark-grey: rgb(44, 44, 44);
    --cyan: rgb(0, 247, 255);
    --ligth-cyan: rgb(180, 252, 255);
    --cyan-clicked: rgb(12, 176, 182);
    --sub-section: rgb(3, 227, 235);
    --background-color: rgb(10, 86, 131);
    --danger: rgb(226, 69, 69);
    --clicked-danger: rgb(161, 51, 51);
    --aprove-green: rgb(111, 238, 111);
}

input {
  outline: none;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}


/*----------  scroll toggles  ----------*/

::-webkit-scrollbar{
    width: 8px;
  }
  
  ::-webkit-scrollbar-thumb{
    background: rgb(12, 176, 182);
    background: var(--cyan-clicked);
    border-radius: 4px;
  }
  
  ::-webkit-scrollbar-track{
    background-color: rgb(241, 241, 241);
    background-color: var(--light-grey);
  }



/*=============================================
=            Generics            =
=============================================*/

.pt-btn {
  font-family: "Blinker";
  cursor: pointer;
  background: rgb(0, 247, 255);
  background: var(--cyan);
  color: white;
  border: none;
  padding: 1% 2.5%;
  border-radius: 5px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.pt-btn.disabled-btn {
  pointer-events: none;
  background: rgb(180, 252, 255);
  background: var(--ligth-cyan);
}



/*=============================================
=            general classes            =
=============================================*/

li {
    list-style: none;
}

.white-element{
    color: white;
}

#back-arrow.white-element{
    padding: auto 0;
    color: white;
}


/*=====  End of general classes  ======*/




img[alt="www.000webhost.com"]{
	display: none;
}

body{
    margin: 0;
}

#login-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 35%;
    margin: 15% auto;
}

#user-img-container{
    font-size: 6rem;
    color: rgb(153, 153, 183);
    background-color: rgb(199, 199, 199);
    padding: 3% 4%;
    margin-bottom: 3%;
    border: 3px solid rgb(43, 43, 43);
    border-radius: 9999px;
}

#fields-container{
    width: 70%
}

.field-container{
    font-family: 'Blinker', sans-serif;
    font-size: 1.3rem;
    margin: 3%;
}

.field-container label{
    box-sizing: border-box;
    padding-left: 4%;
}

.field-container input{
    display: block;
    width: 100%;
    background-color: rgb(196, 196, 196);
    border: 2px solid rgb(43, 43, 43);
    border-radius: 9999px;
    padding: 2%;
    margin-top: 1%
}

input#login-btn{
    cursor: pointer;
    font-family: 'Roboto Condensed';
    width: 25%;
    background-color: var(--cyan);
    color: white;
    margin: 6% auto;
    border-color: var(--cyan);

}
.option-box-container{
    cursor: pointer;
    color: var(--grey);
    width: 15%;
    border: 3px solid var(--grey);
    border-radius: 9px;
    padding: 3% 0;
    margin: 0 2%;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.option-box-container:hover{
    color: rgb(255,255,255);
    background-color: var(--cyan);
    border-color: var(--cyan)
}

.option-box-container:active{
    background-color: var(--cyan-clicked);
    border-color: var(--cyan-clicked);
}

.box-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin:0 auto
}

.box-logo{
    font-size: 3.4rem;
}

.box-text{
    font-family: 'Roboto Condensed';
    font-size: 1.2rem;
    margin:10% 0 0;
}

/*
 
                     _                 
   _ __   ___       (_)_ __ ___   __ _ 
  | '_ \ / _ \ _____| | '_ ` _ \ / _` |
  | | | | (_) |_____| | | | | | | (_| |
  |_| |_|\___/      |_|_| |_| |_|\__, |
                                 |___/ 
 
*/

.option-box-container.no-img{
    height: 7rem
}  

.box-content.no-img{
    width: 80%;
    display: flex;
    align-items: center;
    margin: 10% auto;
}
/*
 
   __  __       _       __  __                  
  |  \/  | __ _(_)_ __ |  \/  | ___ _ __  _   _ 
  | |\/| |/ _` | | '_ \| |\/| |/ _ \ '_ \| | | |
  | |  | | (_| | | | | | |  | |  __/ | | | |_| |
  |_|  |_|\__,_|_|_| |_|_|  |_|\___|_| |_|\__,_|
                                                
 
*/

img[alt="www.000webhost.com"]{
	display: none;
}

#options-page{
    height:100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
#back-arrow{
    cursor: pointer;
    font-size: 3rem;
    margin: 1%;
    color: var(--grey);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

#back-arrow:hover{
    color: var(--cyan)
}
/*
 
   ____       _   _   _                 _   _             __  __           _       _ 
  / ___|  ___| |_| |_(_)_ __   __ _ ___| \ | | __ ___   _|  \/  | ___   __| | __ _| |
  \___ \ / _ \ __| __| | '_ \ / _` / __|  \| |/ _` \ \ / / |\/| |/ _ \ / _` |/ _` | |
   ___) |  __/ |_| |_| | | | | (_| \__ \ |\  | (_| |\ V /| |  | | (_) | (_| | (_| | |
  |____/ \___|\__|\__|_|_| |_|\__, |___/_| \_|\__,_| \_/ |_|  |_|\___/ \__,_|\__,_|_|
                              |___/                                                  
 
*/


/*=============================================
=            Background            =
=============================================*/

#settings-nav-background{
    display: none;
    cursor: pointer;
    background: rgba(90, 90, 90, .2);
    position: fixed;
    height: 100%;
    width: 100%;
    
}


/*=============================================
=            Token giver            =
=============================================*/

#token-giver{
    cursor: default;
    width: 35%;
    max-width: 35%;
    background: white;
    align-self: center;
    margin: 0 0 0 30%;
    text-align: center;
    padding: 1.5%;
    border-radius: 5px;
}

@-webkit-keyframes token-clicked {
    0%{-webkit-transform: scale(1);transform: scale(1);}
    25%{-webkit-transform: scale(1.1);transform: scale(1.1);}
    50%{-webkit-transform: scale(1);transform: scale(1);}
    75%{-webkit-transform: scale(1.1);transform: scale(1.1);}
    100%{-webkit-transform: scale(1);transform: scale(1);}
}

@keyframes token-clicked {
    0%{-webkit-transform: scale(1);transform: scale(1);}
    25%{-webkit-transform: scale(1.1);transform: scale(1.1);}
    50%{-webkit-transform: scale(1);transform: scale(1);}
    75%{-webkit-transform: scale(1.1);transform: scale(1.1);}
    100%{-webkit-transform: scale(1);transform: scale(1);}
}

#token-container{
    font-family: 'Roboto Condensed';
    width: 80%;
    cursor: pointer;
    padding: .5vh;
    color: rgba(0, 0, 0, .5);
    border: calc(1px + .05em) solid rgba(0, 0, 0, .1);
    border-radius: 2px;
    text-align: center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

#token-container:hover{
    color: var(--sub-section);
}

#token-container:active{
    -webkit-animation-name: token-clicked;
            animation-name: token-clicked;
    -webkit-animation-duration: .2s;
            animation-duration: .2s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}



/*=============================================
=            modal container            =
=============================================*/

#settings-nav-modal{
    cursor: default;
    background: var(--background-color);
    width: 20%;
    height: 100%;
    color: white;
    margin-left: auto;
}



/*----------  title  ----------*/

#settings-nav-title{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 5% 0;
    border-bottom: 1px solid var(--cyan-clicked);
}

#settings-nav-title h4{
    font-family: Blinker;
    font-size: 1.6rem;
    margin: 0;
}


/*----------  Setting options  ----------*/

.settings-nav-option{
    cursor: pointer;
    font-family: 'Roboto Condensed';
    display: flex;
    font-size: 1.2rem;
    justify-content: center;
    padding: 4%;
    -webkit-transition: all .1s ease-out;
    transition: all .1s ease-out;
}

.settings-nav-option:hover{
    background: rgba(255, 255, 255, .1);
}

.settings-nav-option:active{
    background: var(--cyan-clicked);
}

#settings-nav-options h5{
    margin: 0;
}

/*
 
    ____          _                     _    _           _       
   / ___|   _ ___| |_ ___  _ __ ___    / \  | | ___ _ __| |_ ___ 
  | |  | | | / __| __/ _ \| '_ ` _ \  / _ \ | |/ _ \ '__| __/ __|
  | |__| |_| \__ \ || (_) | | | | | |/ ___ \| |  __/ |  | |_\__ \
   \____\__,_|___/\__\___/|_| |_| |_/_/   \_\_|\___|_|   \__|___/
                                                                 
 
*/
/*=============================================
=            main container            =
=============================================*/

#alert-container{
    font-family: 'Roboto Condensed';
    display: flex;
    position: fixed;
    width: 44%;
    background: var(--danger);
    color: white;
    justify-content: space-between;
    /* margin: 0 16%; */
    padding: calc(.65rem - 1px) 2%;
    border-radius: 5px;
}

#alert-container.hidden-alert{
    visibility: hidden;
}

/*=============================================
=            Closing Arrow            =
=============================================*/

.alert-closing-btn{
    cursor: pointer;
    -webkit-transition: color .5s ease-in-out;
    transition: color .5s ease-in-out;
}

.alert-closing-btn:hover{
    color: rgba(255, 255, 255, .6);
}


/*
 
    ____                _       _   _               ____             __ _ _      
   / ___|_ __ ___  __ _| |_ ___| \ | | _____      _|  _ \ _ __ ___  / _(_) | ___ 
  | |   | '__/ _ \/ _` | __/ _ \  \| |/ _ \ \ /\ / / |_) | '__/ _ \| |_| | |/ _ \
  | |___| | |  __/ (_| | ||  __/ |\  |  __/\ V  V /|  __/| | | (_) |  _| | |  __/
   \____|_|  \___|\__,_|\__\___|_| \_|\___| \_/\_/ |_|   |_|  \___/|_| |_|_|\___|
                                                                                 
 
*/


/*=============================================
=            Background            =
=============================================*/


#newprofile-modal-background{
    cursor: pointer;
    display: none;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 200;
}



/*=============================================
=            form container            =
=============================================*/


#newprofile-form-container{
    cursor: default;
    background: white;
    overflow-y: auto;
    width: 80%;
    height: 100%;
    margin: 0 auto;
    border: 1px solid var(--grey);  
    box-shadow: 0 0 28px 10px rgba(0, 0, 0, .2);
}


/*=============================================
=            profile name            =
=============================================*/

#profile-name-container{
    font-family: 'Roboto Condensed';
    display: flex;
    width: 40%;
    font-size: 1.3rem;
    justify-content: space-between;
    color: var(--background-color);
    margin: 2% auto;    
}

#profile-name-container input{
    font-family: 'Roboto Condensed';
    width: 68%;
    border: none;
    border-bottom: 2px solid var(--grey);
    padding: 1%;
}


/*=============================================
=            alerts container            =
=============================================*/

#alertsbox{
    display: flex;
    justify-content: center;
}




/*=============================================
=            tests list            =
=============================================*/


#profile-tests-container{
    width: 80%;
    margin: 5% auto;
}

.messege-descriptor{
    font-family: 'Roboto Condensed';
    color: var(--grey);
    margin-left: 8%;
}

#tests-list{
    width: 75%;
	max-height: 25vh;
	text-align: center;
	margin: 3% auto;
	overflow:auto;
	/* border-bottom: 2px solid var(--grey); */
}

#tests-list h5{
    margin: 0;
}

.test-option{
	cursor: pointer;
    font-family: 'Roboto Condensed';
    color:rgb(90,90,90);
	padding: 2% 0;
	border-bottom: 1px solid var(--cyan);
}


.test-option:hover{
	background-color: var(--ligth-cyan);
}



/*=============================================
=            Stats            =
=============================================*/

#profile-stats-conainer{
    font-family: 'Roboto Condensed';
    overflow-y: auto;
    width: 80%;
    height: 30vh;
    color: var(--grey);
    margin: 5% auto;
    padding: 3%;
    border-top: 1px solid var(--dark-grey);
    border-bottom: 1px solid var(--dark-grey);
}

.profile-testattribs-container{
    width: 100%;
    background: var(--cyan);
    padding-bottom: 1rem;
}

.profile-testattribs-container:first-child{
    border-radius: calc(.30rem - 1px) calc(.30rem - 1px) 0 0;
}

.profile-testattribs-container:last-child{
    border-radius:  0 0 calc(.30rem - 1px) calc(.30rem - 1px);
}

h2.profile-testattribs-code{
    font-family: 'Blinker';
    background-color: rgba(0, 0, 0, .03);
    margin: 0;
    color: white;
    padding: calc(.75rem - 1px) 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    border-top: 1px solid rgba(0, 0, 0, .125);
}

.profile-testattribs-container:first-child h2{
    border-top: none;
}


/*----------  Attribut  ----------*/

.profile-test-attrib{
    display: flex;
    width: 50%;
    color: white;
    justify-content: space-around;
    margin: 1rem 0 0 1.3rem;
}

.profile-test-attrib-name{
    font-size: 1.1rem;
    margin: 0;
    text-transform: capitalize;
}

.profile-attrib-input{
    width: 20%;
}

.profile-attrib-input label{
    font-size: .8rem;
    margin-right: .6rem;
}

.profile-attrib-input input{
    font-family: 'Roboto Condensed';
    background: rgba(0, 0, 0, 0);
    width: 40%;
    color: white;
    border: none;
    border-bottom: .15em solid rgba(0, 0, 0, .225);
}


/*=============================================
=            controls            =
=============================================*/


#profile-controls{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 2%;
}


#create-profile-btn{
    font-family: 'Roboto Condensed';
    cursor: pointer;
    padding: 0.1rem 4%;
    border: 1px solid var(--cyan);
    border-radius: 5px;
    -webkit-transition: all .6s ease-in;
    transition: all .6s ease-in/*cubic-bezier(0.4, -0.29, 0.53, 0.79);*/
}

.ready-btn-state{
    background: var(--cyan);
    color: white;   
}

.ready-btn-state:active{
    background: var(--cyan-clicked);
    border-color: var(--cyan-clicked) !important;
}

.not-ready-btn-state{
    background: white;
    color: var(--cyan);
}








/*
 
   _____         _  _____     _              ____        _        
  |_   _|__  ___| ||_   _|__ | | _____ _ __ |  _ \  __ _| |_ __ _ 
    | |/ _ \/ __| __|| |/ _ \| |/ / _ \ '_ \| | | |/ _` | __/ _` |
    | |  __/\__ \ |_ | | (_) |   <  __/ | | | |_| | (_| | || (_| |
    |_|\___||___/\__||_|\___/|_|\_\___|_| |_|____/ \__,_|\__\__,_|
                                                                  
 
*/


/*=============================================
=            Background            =
=============================================*/

#test-token-data-background
{
    display: none;
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    width: 100%;
    height: 100vh;
}



/*=============================================
=            main-container            =
=============================================*/

#ttd-main-container{
    background-color: white;
    overflow-y: auto;
    width: 60%;
    height: 100vh;
    margin: 0 auto;
    padding: .5em 0;
    border-right: 1px solid var(--background-color);
    border-left: 1px solid var(--background-color);
}

#ttd-main-container h4{
    font-family: Blinker;
    font-size: 1.4rem;
    color: var(--cyan-clicked);
    text-align: center;
    margin: 0;
}


/*=============================================
=            tokens container            =
=============================================*/

#tokens-container{
    font-family: 'Roboto Condensed';
    background: var(--darker-white);
    width: 80%;
    height: 80vh;
    margin: 4% auto 0;
    border: calc(1px - .02em) solid var(--light-grey);
    border-radius: 5px;
}


/*----------  tokens  ----------*/
.user-ttoken{
    cursor: pointer;
    color: white;
    padding: 1em;
    border-bottom: 1px solid var(--light-grey);
    -webkit-transition: all .3s ease-in;
    transition: all .3s ease-in;
}

.user-ttoken .token-upper-info-container, .user-ttoken .token-info{
    display: flex;
    width: 100%;
    /* justify-content: space-between; */
}

.token-upper-info-container .interviewee-name{
    width: 75%;
}

.token-upper-info-container .interviewee-name input{
    font-family: 'Roboto Condensed';
    background: none;
    color: white;
    font-size: 1.2rem;
    border: none;
}

.token-upper-info-container .token-status{
    width: 20%;
    color: rgba(255, 255, 255, 0.7);
}

.token-upper-info-container .del-btn{
    -webkit-transition: color 1s ease-in-out;
    transition: color 1s ease-in-out;
}

.token-upper-info-container .del-btn:hover{
    color: rgba(247, 77, 77, 0.8);
}

.user-ttoken .token-info{
    justify-content: space-around;
    font-size: 0.9rem;
    margin-top: 1%;
}

.user-ttoken.token-test-done{
    background-color: var(--aprove-green);
}

.user-ttoken.token-test-done:hover{
    background-color: rgba(111, 238, 111, 0.8);
}

.user-ttoken.token-test-pending{
    background-color: var(--danger);
}

.user-ttoken.token-test-pending:hover{
    background: rgba(226, 69, 69, 0.8);
}


/*=============================================
=            close btn            =
=============================================*/

#ttd-close-btn{
    color: var(--dark-grey);
    text-align: center;
    margin-top: 2%;
}

#ttd-close-btn i{
    cursor: pointer;
    font-size: 3em;
}



/*
 
    ____                _       _   _               _____         _   
   / ___|_ __ ___  __ _| |_ ___| \ | | _____      _|_   _|__  ___| |_ 
  | |   | '__/ _ \/ _` | __/ _ \  \| |/ _ \ \ /\ / / | |/ _ \/ __| __|
  | |___| | |  __/ (_| | ||  __/ |\  |  __/\ V  V /  | |  __/\__ \ |_ 
   \____|_|  \___|\__,_|\__\___|_| \_|\___| \_/\_/   |_|\___||___/\__|
                                                                      
 
*/


/*=============================================
=            main containers            =
=============================================*/

#new-test-background {
    cursor: pointer;
    display: none;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.1);
    position: fixed;
}



/*=============================================
=            modal            =
=============================================*/


#new-test-container{
    font-family: 'Blinker';
    cursor: default;
    width: 60%;
    height: 100vh;
    background-color: white;
    margin: 0 auto;
    padding: 0.2% 0;
    border-right:1px solid var(--cyan);
    border-left:1px solid var(--cyan);
}


/*----------  title  ----------*/


#cnt-title{
    width: 20%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: lighter;
    margin: 0 auto;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}


/*=============================================
=            new test input            =
=============================================*/


.cnt-input h4{
    font-family: 'Roboto Condensed';
    color: var(--grey);
    font-weight: lighter;
    margin: 0 -0.5%;
}


.cnt-input input{
    font-family: "Roboto Condensed";
    width: 100%;
    background: var(--light-grey);
    color: var(--cyan-clicked);
    font-size: 1.8rem;
    border: 1px solid var(--cyan);
    border-radius: 5px;
    margin: 1% 0 0;
}

.cnt-input.cnt-error input{
    color: var(--danger);
    border-color: var(--danger);
}

.cnt-input.cnt-error .cnt-input-warning {
    display: block;
    font-size: .7em;
    color: var(--danger);
}


.cnt-input input::-webkit-input-placeholder{

    color: rgba(0, 0, 0, 0.3);  
}


.cnt-input input::-moz-placeholder{

    color: rgba(0, 0, 0, 0.3);  
}


.cnt-input input:-ms-input-placeholder{

    color: rgba(0, 0, 0, 0.3);  
}


.cnt-input input::-ms-input-placeholder{

    color: rgba(0, 0, 0, 0.3);  
}


.cnt-input input::placeholder{

    color: rgba(0, 0, 0, 0.3);  
}

#test-name-input{
    width: 70%;
    margin: 3% auto;
}


/*=============================================
=            middle options            =
=============================================*/

#cnt-middle-options {
    display: flex;
    width: 80%;
    margin: 0 auto;
    justify-content: space-between;
}




/*=============================================
=            Measured values            =
=============================================*/

#cnt-measured-values-container {
    width: 8vw;
    background-color: var(--light-grey);
    font-size: 0.9rem;
    height: 12vh;
    border-radius: 5px;
    border: 1px solid black;
}

#cnt-mv-values {
    max-height: 73%;
    overflow-x: hidden;
    overflow-y: auto;
}

.measured-value-container input {
    font-family: Blinker;
    width: 100%;
    background: none;
    border: none;
    border-bottom: 1px solid var(--dark-grey);
    padding: .5%;
    text-align: center;
}

#measured-values-adder-btn {
    border-radius: 5px;
}


/*----------  values adder  ----------*/

.adder-btn {
    width: 100%;
    background: var(--cyan);
    text-align: center;
    color: white;
    border-radius: 0 0 10px 10px;
    border: none;
    padding-bottom: 3%;
}


.adder-btn input {
    width: 2em;
    background: none;
    color: white;
    border: none;
    border-bottom: 2px solid var(--sub-section);
    text-align: center;
}


/*=============================================
=            middle linear test data            =
=============================================*/

#cnt-middle-linear-test-data{
    display: flex;
    width: 60%;
    align-items: center;
    justify-content: flex-end;
}


/*----------  awnser  ----------*/

#awnsers-p-question-input {
    margin-right: 4rem;
}

#awnsers-p-question-input input {
    font-size: 1.3rem;
    width: 2em;
}


/*----------  test short name  ----------*/

#test-shortname-input {
    width: 60%;
}

#test-shortname-input input{
    width: 60%;
    font-size: 1.3rem;
    text-transform: uppercase;
}






/*=============================================
=            questions container            =
=============================================*/

#cnt-questions-container{
    overflow-y: auto;
    overflow-x: hidden;
    width: 80%;
    background: var(--light-grey);
    height: 45vh;
    border-radius: 5px;
    border: 1px solid var(--cyan-clicked);
    margin: 5% auto;

}

#question-adder-btn {
    outline: none;
    padding: 1%;
}

#question-adder-btn input {
    font-size: 2em;
}

/*----------  Question editor  ----------*/

.question-editor-container {
    background-color: white;
    padding: 2%;
    border-bottom: 1px solid var(--dark-grey);
}

.question-editor-container:last {
    border: none;
}

.qe-title input{
    font-size: 1rem;
    margin-bottom: 1em;
    width: 100%;
}

.ftr-qe-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.qe-possible-awnsers-contianer {
    overflow-y: auto;
    width: 18vw;
    height: 4em;
    border: 1px solid var(--dark-grey);
    border-radius: 5px;
}

.possible-awnser-container {
    display: flex;
    border-bottom: 1px solid var(--light-grey);
}

.possible-awnser-container :last-of-type {
    margin-bottom: .5em;
}

.possible-awnser-container input{
    font-family: Blinker;
    background: none;
    padding: none;
    border: none;
    outline: none;
}

.possible-awnser-container .awnser-value {
    width: 2em;
}

.possible-awnser-container p{
    margin: 0 1em;
}

.qe-stat-measuerd {
    width: 5vw;
    height: 1.5rem;
}


/*=============================================
=            controls            =
=============================================*/

#cnt-questions-control-footer {
    display: flex;
    justify-content: space-around;
}






/*
 
                __ _       _     _              _ _____         _       
   _   _ _ __  / _(_)_ __ (_)___| |__   ___  __| |_   _|__  ___| |_ ___ 
  | | | | '_ \| |_| | '_ \| / __| '_ \ / _ \/ _` | | |/ _ \/ __| __/ __|
  | |_| | | | |  _| | | | | \__ \ | | |  __/ (_| | | |  __/\__ \ |_\__ \
   \__,_|_| |_|_| |_|_| |_|_|___/_| |_|\___|\__,_| |_|\___||___/\__|___/
                                                                        
 
*/


/*=============================================
=            Main container            =
=============================================*/

#select-ut-modalbackground{
    display: none;
    width: 100%;
    position: fixed;
    z-index: 3;

}

#select-ut-container{
    display: flex;
    background: white;
    width: 35%;
    flex-direction: column;
    align-items: center;
    margin: 8% auto;
    border: 1px solid var(--grey);
    border-radius: 5px;
    box-shadow: 0 0 14px 0 rgb(197, 197, 197);
}


/*=============================================
=            titulo            =
=============================================*/

#select-ut-title-container{
    font-family: 'Blinker';
    color: var(--grey)
}


/*=============================================
=            uts Options            =
=============================================*/

#uts-container{
    font-family: 'Roboto Condensed';
    overflow-y: auto;
    width: 70%;
    height: 20rem;
    max-height: 20rem;
    margin-bottom: 6%;
    border-right: 1px solid var(--grey);
    border-left: 1px solid var(--grey);
}

.ut-option-container{
    cursor: pointer;
    background: var(--danger);
    color: white;
    padding: 4%;
    text-align: center;
    box-shadow: inset 0 0 5px 0 var(--grey);
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.ut-option-container:first-child{
    margin-top: 2%;
}

.ut-option-container:active{
    background: var(--clicked-danger);
}


/*=============================================
=            controls            =
=============================================*/


#select-uts-controls{
    display: flex;
    cursor: pointer;
    color: var(--dark-grey);
    font-size: 1.3rem;
    margin-bottom: 4%;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

#select-uts-controls:active{
    color: var(--grey);
}





/*
 
                                   _ 
    __ _  ___ _ __   ___ _ __ __ _| |
   / _` |/ _ \ '_ \ / _ \ '__/ _` | |
  | (_| |  __/ | | |  __/ | | (_| | |
   \__, |\___|_| |_|\___|_|  \__,_|_|
   |___/                             
 
*/



#options-page.non-vertical{
    height:90%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}


/*=============================================
=            Controls            =
=============================================*/

#tests-menu-controls{
    display: flex;
    justify-content: space-between;
}

#tests-menu-controls span{
    cursor: pointer;
    font-size: 3rem;
    margin: 1%;
    color: var(--grey);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

#back-arrow:hover{
    color: var(--cyan)
}

#settings-btn{
    -webkit-transition: all .07s linear;
    transition: all .07s linear;

}

#settings-btn:active{
    color: var(--cyan); 
}


/*=============================================
=            Form container            =
=============================================*/

#form-title-container{
    display: flex;
    font-family: 'Blinker';
    font-size:1.6rem;
    text-align: center;
    justify-content: center;
    /* margin-bottom: 20% */
}

#form-options-container{
    font-family: 'Roboto Condensed';
    width: 80%;
}



/*=============================================
=            Fieldset inputs            =
=============================================*/


fieldset#inputs-container{
    width: 40%;
    margin: 0 auto;
    border: none
}

.form-input-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-input-container label{
    align-self: flex-start;
    margin: 0 0 1% 10%;
    font-size: 1.5rem;
    color: var(--sub-section);
}

.form-input-container input,.form-input-container select{
    display: block;
    width: 70%;
    border-radius: 5px;
    border: 3px solid var(--grey);
    font-size: 1.3rem;
    margin: 0 0 3% 3%;

}


/*----------  ok btn  ----------*/

/* #ok-btn-container{
    width: 90%
} */

#test-menu-ok-btn{
    cursor: pointer;
    font-family: 'Roboto Condensed';
    display: block;
    width: 15%;
    background-color: var(--sub-section);
    color: white;
    margin: 2% auto;
    padding: 1%;
    border-radius: 5px;
    border: none;
}



/*----------  General Boton  ----------*/

.boton{
    cursor: pointer;
    font-family: 'Roboto Condensed';
    font-size: 1.2rem;
    display: block;
    width: 15%;
    background-color: var(--sub-section);
    color: white;
    padding: 1%;
    border-radius: 5px;
    border: none
}



/*=============================================
=            Unfinished Tests            =
=============================================*/

#unfinished-tests-modal-btn-container{
    width: 100%;
    display: flex;
    justify-content: center;

}

#unfinished-tests-modal-container{
    cursor: pointer;
    width: 40%;
    height: 3rem;
    box-sizing: border-box;
    background: var(--danger);
    color: white;
    margin: 3% 0;
    padding: 1%;
    border-radius: 5px;
    text-align: center;
    -webkit-transition: all .3s ease-in;
    transition: all .3s ease-in;
}

#unfinished-tests-modal-container h5{
    font-family: 'Blinker';
    margin: 0;

}

#unfinished-tests-modal-container:hover{
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
}

#unfinished-tests-modal-container:active{
    background: var(--clicked-danger);
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
}

/*
 
   _       _                  _                        
  (_)_ __ | |_ ___ _ ____   _(_) _____      _____ _ __ 
  | | '_ \| __/ _ \ '__\ \ / / |/ _ \ \ /\ / / _ \ '__|
  | | | | | ||  __/ |   \ V /| |  __/\ V  V /  __/ |   
  |_|_| |_|\__\___|_|    \_/ |_|\___| \_/\_/ \___|_|   
                                                       
 
*/ 


/*=============================================
=            A1            =
=============================================*/


/*----------  General  ----------*/

#a1-test-container{
    font-family: 'Roboto Condensed';
    width: 100%;
    padding: 1%;
}


/*----------  title  ----------*/

#title-questionary-container{
    font-family: 'Blinker';
    text-align: center;
    width: 60%;
    font-size: 1.5rem;
    color: var(--grey);
    border-bottom: 8px solid var(--cyan);
    margin: 0 auto;
}


/*----------  Question  ----------*/

#a1-question-container{
    width: 80%;
    background-color: var(--light-grey);
    margin: 2% auto;
    padding: 1%;
    border: 1px solid var(--grey); 
    border-radius: 20px
}

#a1-question-container h3, #a1-question-container h3 span{
    color: var(--dark-grey);
    text-align: center
}

#a1-question-container span{
    margin-left: 3%;
    color: var(--grey);
}


/*----------  Anwsers  ----------*/

#awnsers-container{
    display: flex;
    width: 60%;
    flex-direction: column;
    margin: 0 auto;
}

#awnsers-container .awnser-text-container{
    cursor: pointer;
    border-bottom: 2px solid var(--ligth-cyan);
    padding-left: 7%;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out 
}

#awnsers-container .awnser-text-container:hover{
    background-color: var(--ligth-cyan);
}

#awnsers-container .awnser-text-container h4::before{
    content: '> ';
    font-size: 1.4rem;
    color: var(--cyan-clicked)
}


/*----------  Range answer  ----------*/

#range-answer-container{
    padding-bottom: 3%; 
    border-top: 4px solid var(--ligth-cyan);
    border-bottom: 4px solid var(--ligth-cyan);
}

#range-answer-text-container{
    display: flex;
    width: 57%;
    justify-content: space-between;
    margin: 0 auto;
}

#answer-inputs-container{
    width: 57%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto; 
}

.range-anwser-input{
    cursor: pointer;
    border:2px solid var(--cyan);
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}


/*----------  Boton Next  ----------*/

#next-question-btn-container{
    display: flex;
    justify-content:flex-end;
    padding: 5% 10%; 
}

#next-question-btn-container .boton-controls{
    font-family: 'Roboto Condensed';
    background-color: var(--cyan);
    color: white;
    font-size: 1.1rem;
    padding: 1% 3%;
    margin-left: 2%;
    border: none;
    border-radius: 5px;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in; 
}

#next-question-btn-container .boton-controls:hover{
    cursor: pointer;
    background-color: var(--sub-section)
}
/*
 
   ____                 _ _       
  |  _ \ ___  ___ _   _| | |_ ___ 
  | |_) / _ \/ __| | | | | __/ __|
  |  _ <  __/\__ \ |_| | | |_\__ \
  |_| \_\___||___/\__,_|_|\__|___/
                                  
 
*/


/*=============================================
=            Header            =
=============================================*/

header#results-header{
    font-family: 'Blinker';
    background-color: var(--background-color);
    display: flex;
    justify-content: space-between;
    font-size: 1.5rem;
    color: white;
    text-transform: uppercase;
    padding: 0.3rem;
}

.page-title{
    margin-left: 35%;
}

#back-arrow.white-element{
    display: flex;
    margin-right: 2%;
    justify-content: center;
    align-items: center;
}

/*=============================================
=            Results            =
=============================================*/

#results-area{
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    margin: 0 auto;
}

.interview-results-container{
    cursor: pointer;
    font-family: 'Roboto Condensed';
    width: 25%;
    height: 8em;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    border: 1px solid var(--grey);
    margin: 3%;
    padding: 1%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

.interview-results-container:hover{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    border: 1px solid #fe4918;
    box-shadow: 0 4px 28px 0 rgba(0, 0, 0, 0.349);
}

.interviewee-name-container{
    display: flex;
    width: 90%;
    margin: 0 auto;
    justify-content: space-between;
}


/*=============================================
=            controls            =
=============================================*/

.interviewee-name-container .controls-container{
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 1.7rem;
    color: var(--grey);
    margin-left: 3%;
}

.interviewee-name-container .controls-container .cross:hover{
    color: var(--danger);
}

.interviewee-name-container .controls-container i:hover{
    color: var(--cyan);
    cursor: pointer;
}

/*=====  End of contros  ======*/

.interviewee-name-text{
    font-family: 'Blinker';
    font-size: 1.5rem;
    color: var(--dark-grey);
}


/*=============================================
=            Sidebar            =
=============================================*/

#results-side-bar{
    overflow-x: hidden; 
    background-color: rgb(241, 241, 241);
    height: 100%; 
    width: 0; 
    position: fixed; 
    z-index: 1; 
    top: 0; 
    right: 0;
    /* border-left: 1px solid var(--cyan); */
    padding-top: 60px; 
    box-shadow: -4px 0 30px 0 rgba(0, 0, 0, 0.349);
    -webkit-transition: 0.5s;
    transition: 0.5s; 
}

#results-side-bar-title{
    font-family: "Roboto Condensed";
    display: flex;
    width: 80%;
    justify-content: space-between;
    font-size: 2rem;
    border: 1px solid var;
    margin: 0 auto;
}

span.results-title-text{
    color: var(--background-color);
}

#close-sidebar-btn{
    cursor: pointer;
    color: var(--grey);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

#close-sidebar-btn:hover{
    color: var(--danger)
}


/*----------  Stats  ----------*/

#results-stats-container{
    height: 70%;
    width: 90%;
    overflow-y: auto;
    font-family: "Blinker";
    font-size: 1.2rem;
    color: var(--dark-grey);
    border-top: 2px solid var(--dark-grey);
    margin: 7% auto 0;
    padding: 5% 0;
}


/*----------  Results Drop Down  ----------*/

.rdd-attrib-container{
    background: var(--light-grey);
    color: var(--cyan-clicked);
    margin: 0.3rem 0 0.3rem 0;
    padding: 1% 0% 2%;
    border-bottom: 1px solid var(--sub-section);
}


.rdd-title-container{
    cursor: pointer;
    display: flex;
    height: 100%;
    align-items: center;
    font-size: 1.2rem;
    -webkit-transition: all .3s ease-in;
    transition: all .3s ease-in;   
}

.rdd-attrib-container:hover{
    background: rgba(255, 255, 255, 0.9);
}

.rdd-title-container:active{
    background: rgba(255, 255, 255, 0.1);
}

.rdd-title-container span{
    margin-left: 5%;
}

.rdd-stats-container{
    display: none;
    overflow-y: auto;
    background: var(--cyan);
    max-height: 15rem;
    color: var(--dark-grey);
    padding: 2% 0;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    box-shadow: inset 0 2px 12px 0 rgba(0, 0, 0, 0.3);
}


.rdd-stat{
    display: flex;
    align-items: center;
    padding-left: 10%;
}

.rdd-stat p, .rdd-stat span{
    margin: 1% calc(1% - 1px);
}


/*----------  diferent states of acceptency  ----------*/

.rdd-stat span.perfect-fit{
    color: rgb(54, 170, 0)
}

.rdd-stat span.warning{
    color: rgb(236, 201, 4)
}

.rdd-stat span.danger{
    color: var(--danger)
}


/*
 
   _____     _              _   _               _____           _ 
  |_   _|__ | | _____ _ __ | | | |___  ___ _ __| ____|_ __   __| |
    | |/ _ \| |/ / _ \ '_ \| | | / __|/ _ \ '__|  _| | '_ \ / _` |
    | | (_) |   <  __/ | | | |_| \__ \  __/ |  | |___| | | | (_| |
    |_|\___/|_|\_\___|_| |_|\___/|___/\___|_|  |_____|_| |_|\__,_|
                                                                  
 
*/

/*=============================================
=            main-container            =
=============================================*/

#token-user-done{
    font-family: 'Roboto Condensed';
    overflow: -moz-hidden-unscrollable;
    width: 100vw;
    height: 95vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--grey);
    border-top: 7px solid var(--cyan);
}


/*=============================================
=            content            =
=============================================*/

#tud-content{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}



/*----------  smile  ----------*/

#nice-face-container{
    color: var(--aprove-green);
    font-size: 10rem;
    text-align: center;
}


/*----------  main-message  ----------*/

#main-message-container{
    font-size: 2rem;
}

#sub-msg-container, #main-message-container{
    text-align: center;
}





/*

    font-family: 'Blinker', sans-serif;

    font-family: 'Roboto Condensed', sans-serif;

*/
